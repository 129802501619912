import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';
import { LAYOUT_SIZES } from 'Style/layout';

// Utils
import SectionUtil from 'Utils/content/section-util';

// Components
import Button, {
  StyleModifiers,
  StyleVariations,
} from 'Webapp/shared/app/components/button';
import FavoriteButton from '../containers/section-header/favorite-button';
import AuthorAvatar from './attribution/author-avatar';
import FollowButton from 'Webapp/shared/app/components/follow-button';

import withShare from '../hocs/withShare';
import withFlip from '../hocs/withFlip';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';

export const StyledGenericSectionHeader = styled.header(
  {
    textAlign: 'center',
    padding: `${SPACING.BASE6X} 0`,
    marginBottom: SPACING.BASE4X,
  },
  BREAKPOINTS.tabletPortraitUp({
    padding: `${SPACING.BASE12X} 0`,
    marginBottom: SPACING.BASE6X,
  }),
);

const AuthorWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: SPACING.BASE,
});
export const GenericHeaderTitle = styled.h1(UI_TEXT_TYPES.PAGE_TITLE, {
  marginBottom: SPACING.BASE,
});
const TopicDescription = styled.p(
  {
    margin: `0 auto ${SPACING.MEDIUM} auto`,
    maxWidth: LAYOUT_SIZES.MAIN_COLUMN_HALF_WIDTH,
  },
  UI_TEXT_TYPES.BODY_2,
);

const SectionHeaderActions = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignContent: 'center',
  gap: SPACING.MEDIUM,
  marginBottom: SPACING.MEDIUM,
});

const SectionHeaderMetrics = styled.p(UI_TEXT_TYPES.SUPPORTING, {
  marginBottom: SPACING.LARGE,
});

function GenericSectionHeader({
  section,
  path,
  topicDescriptions,
  onShare,
  onFlip,
  isAmp,
  t,
}) {
  const { author, metricsText } = section;
  const decoratedTitle = SectionUtil.getDecoratedSectionTitle(section);
  const topicDescription = SectionUtil.customizedTopicText(
    path,
    'description',
    topicDescriptions,
  );

  return (
    <StyledGenericSectionHeader>
      {!section.isTopic && author && (
        <AuthorWrapper>
          <AuthorAvatar author={author} avatarSize={80} />
        </AuthorWrapper>
      )}
      <div>
        <GenericHeaderTitle>{decoratedTitle}</GenericHeaderTitle>
        {topicDescription && (
          <TopicDescription>{topicDescription}</TopicDescription>
        )}
        {metricsText && (
          <SectionHeaderMetrics data-test-id="followers">
            {metricsText}
          </SectionHeaderMetrics>
        )}
        <SectionHeaderActions>
          <FollowButton
            namePrefix="section-header"
            section={section}
            styleModifier={[StyleModifiers.INVERSE]}
          />
          <FavoriteButton section={section} />
          {!isAmp && (
            <Button
              onClick={() => onShare({ section })}
              name="section-header-share"
              label={t('share')}
              styleVariation={StyleVariations.SECONDARY}
            />
          )}
          {!isAmp && (
            <Button
              onClick={() => onFlip({ section })}
              name="section-header-flip"
              label={t('flip')}
              styleVariation={StyleVariations.SECONDARY}
            />
          )}
        </SectionHeaderActions>
      </div>
    </StyledGenericSectionHeader>
  );
}

GenericSectionHeader.propTypes = {
  isAmp: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  topicDescriptions: PropTypes.array,
  onShare: PropTypes.func.isRequired,
  onFlip: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

GenericSectionHeader.defaultProps = {
  topicDescriptions: null,
  isFollowing: false,
};

export default connector(connectAmp)(
  withT(withShare(withFlip(GenericSectionHeader))),
);
